import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Notifications from "./views/Notifications";
import Liens from "./views/Liens";
import Jobs from "./views/Jobs";
import Pdf from "./views/pdf";
import Approve from "./views/Approve";
import ProjectManagers from "./views/Project-managers";
import Materials from "./views/Materials";
import Contractors from "./views/Contractors"
import Contractor from "./views/Contractor"
import allPayments from "./views/allPayments";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

const App = () => {
  const { isLoading, error } = useAuth0();


  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <NavBar />
        <Container className="flex-grow-1 mt-5">
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/liens" component={Liens} />
            <Route path="/jobs" component={Jobs} />
            <Route path="/approve" component={Approve} />
            <Route path="/project-managers" component={ProjectManagers} />
            <Route path="/contractors" component={Contractors} />
            <Route path="/contractor" component={Contractor} />
            <Route path="/materials" component={Materials} />
            <Route path="/all-payments" component={allPayments} />
            <Route path="/pdf" component={Pdf} />
            <Route path="/notifications" component={Notifications} />
          </Switch>
        </Container>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
