import React, { Component, useRef } from "react";
import {
  useAuth0,
  withAuthenticationRequired
} from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { Container, Row, Col } from "reactstrap";
import {
  Form
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Password } from 'primereact/password';
import { InputText } from "primereact/inputtext";
import { faTrash, faEdit, faEye } from '@fortawesome/free-solid-svg-icons'
import styles from './styles.module.css';

const job_total = 0;
class AddContractor extends Component {


  constructor(props) {
    console.log(props)
    super(props);
    const user = this.props.user;
    this.toast = React.createRef(null);
    this.state = {
      "userFull": {
        "_id": "",
        "name": ""
      },
      "newPM": {
        "name": "",
        "password": "",
        "company": "",
        "email": ""
      },
      "newPMState": false,
      "editPMState": false,
      "editPMID": null,
      "projectManagers": [],
      "newPMErrorState": false,
      "newPMError": "",
      "editPMState": false
    }
    this.addProjectManager = this.addProjectManager.bind(this);
    this.closePMModel = this.closePMModel.bind(this);
    this.editProjectManager = this.editProjectManager.bind(this);
    this.addProjectManagerSave = this.addProjectManagerSave.bind(this);
    this.editProjectManagerSave = this.editProjectManagerSave.bind(this);
    this.deleteProjectManager = this.deleteProjectManager.bind(this);

  };

  async updatePmsUpdate(id) {
    console.log(id)
    var response = await fetch("https://api.sandershylandtest.com/all/contractors", {
        // mode:'no-cors',
       method: "GET",
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((response) => response.json())
      console.log(response)

      return response

  }

  addProjectManager() {
     var form = {
                "email": "",
                "name": "",
                "password": "",
                "company": ""
          };
    this.setState({
      newPMState: true,
      editPMID: null,
      editPMState: false,
      newPM: form
    });
  }

  editProjectManager(index) {
    var currentPMEdit = this.state.projectManagers[index];
    this.setState({
      editPMState: true,
      newPMState: true,
      newPM: currentPMEdit,
      editPMID: currentPMEdit._id
    });
  }

  async addProjectManagerSave(e) {
    e.preventDefault()
    console.log(this.state.newPM)
    if (this.state.newPM.password === this.state.newPM.passwordConfirm) {
    var newPM = await fetch("https://api.sandershylandtest.com/contractor/add/", {
           method: "POST",
           // mode:'no-cors',
           headers: {
             "Content-Type": "application/json",
           },
           body: JSON.stringify(this.state.newPM),
        })
        .then((newPM) => newPM.json())
        .catch(function(error) {
          console.log(error);
        });
        console.log(newPM)
        if (newPM.success === false) {
         this.setState({
          newPMErrorState: true,
          newPMError: newPM.error,
        }); 
        } else {
           var form = {
                "email": "",
                "name": "",
                "password": "",
                "company": ""
          };
        this.setState({
          newPMErrorState: false,
          newPMState: false,
          editPMState: false,
          newPM: form,
        });
        this.handlePmsUpdate().then((data) => {
          console.log(data)
          this.setState({
            projectManagers: data
          });
        }) 
      }

    } else {
      this.toast.current.show({severity:'error', summary: 'Error', detail:'PASSWORDS DO NO MATCH! PLEASE REENTER!', life: 3000});
    }
       
      }


  async editProjectManagerSave(e) {
    e.preventDefault()
    console.log(this.state.newPM)
    if (this.state.newPM.password === this.state.newPM.passwordConfirm) {
    var newPM = await fetch("https://api.sandershylandtest.com/contractor/save/"+ this.state.editPMID, {
           method: "POST",
           // mode:'no-cors',
           headers: {
             "Content-Type": "application/json",
           },
           body: JSON.stringify(this.state.newPM),
        })
        .then((newPM) => newPM.json())
        .catch(function(error) {
          console.log(error);
        });
        console.log(newPM)
        if (newPM.success === false) {
         this.setState({
          newPMErrorState: true,
          newPMError: newPM.error,
        }); 
        } else {
           var form = {
                "email": "",
                "name": "",
                "password": "",
                "company": ""
          };
        this.setState({
          newPMErrorState: false,
          newPMState: false,
          editPMState: false,
          newPM: form,
        });
        }
      } else {
        console.log('ERROR')
        console.log(this.toast.current)
       this.toast.current.show({severity:'error', summary: 'Error', detail:'PASSWORDS DO NO MATCH! PLEASE REENTER!', life: 3000});
      }
      }



  async deleteProjectManager(id) {

    var response = await fetch("https://api.sandershylandtest.com/contractor-delete/"+ id, {
        // mode:'no-cors',
       method: "POST",
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((response) => response.json())
      console.log(response)
      this.setState({
          projectManagers: response
        });
      return response

  }

  closePMModel() {
    var clearform = this.state.newPM;
    clearform = [
          {
            "email": "",
            "name": "",
            "password": "",
            "passwordConfirm": "",
            "company": ""
          }
        ];
    this.setState({
      newPMState: false,
      newPM: clearform
    });
  }

  inputPMChange(name, value) {
    console.log(name)
    console.log(value)
    const formCurrent = this.state.newPM;
    formCurrent[name] = value;
    this.setState({
      newPM: formCurrent
    });
    console.log(this.state.newPM)
  }

  async handlePmsUpdate(id) {
    console.log(id)
    var response = await fetch("https://api.sandershylandtest.com/all/contractors", {
        // mode:'no-cors',
       method: "GET",
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((response) => response.json())
      console.log(response)

      return response

  }



  componentDidMount() {
    var state_check;
    if (state_check != this.state) {
      this.handlePmsUpdate().then((data) => {
        console.log(data)
        this.setState({
          projectManagers: data
        });
        var state_check = this.state;
      })
    }



  }


  componentDidUpdate(prevProps, prevState, snapshot, nextProps) {
    console.log(prevProps.newPMState)
    console.log(this.props.newPMState)
    if(prevProps.newSettings !== this.props.newSettings) {
      if ( this.props.newSettings )
        this.setState({
          editPMState: this.props.newSettings.state,
          newPMState: this.props.newSettings.state,
          newPM: this.props.newSettings.pm,
          editPMID: this.props.newSettings.id
        });
    }
  }

  render () {
    return(
      <div>
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md>
          <Button raised rounded label="ADD CONTRACTOR" severity="success" onClick = {(e) => {this.addProjectManager()}} />
        </Col>
      </Row>
    <div className = {this.state.newPMState ? 'newContractorFormHolder active' : 'newContractorFormHolder'}>
      <div className="newContractorForm">

      <Form>
      <div className="modelTitle">Add a New Contractor</div>

      <div className={this.state.newPMErrorState ? 'error active' : 'error'}>{this.state.newPMError}</div>
      <Form.Group className = "form_row full" >
      <Form.Label >
      <span > Name: < /span>
      {/* <Form.Control type = "text" */}
      {/* name = "name" */}
      {/* value = {this.state.newPM.name} */}
      {/* onChange = { */}
      {/*   (e) => { */}
      {/*     this.inputPMChange("name", e.target.value) */}
      {/*   } */}
      {/* }/> */}
      <InputText value={this.state.newPM.name} onChange={(e) => this.inputPMChange("name", e.target.value)} />
      </Form.Label>
      </Form.Group>
      <Form.Group className = "form_row full" >
      <Form.Label >
      <span > Email: < /span>
      {/* <Form.Control type = "email" */}
      {/* name = "name" */}
      {/* value = {this.state.newPM.email} */}
      {/* onChange = { */}
      {/*   (e) => { */}
      {/*     this.inputPMChange("email", e.target.value) */}
      {/*   } */}
      {/* }/> */}
      <InputText value={this.state.newPM.email} onChange={(e) => this.inputPMChange("email", e.target.value)} />
      </Form.Label>
      </Form.Group>
      <Form.Group className = "form_row full" >
      <Form.Label >
      <span > Company: < /span>
      {/* <Form.Control type = "company" */}
      {/* name = "name" */}
      {/* value = {this.state.newPM.company} */}
      {/* onChange = { */}
      {/*   (e) => { */}
      {/*     this.inputPMChange("company", e.target.value) */}
      {/*   } */}
      {/* }/> */}
      <InputText value={this.state.newPM.company} onChange={(e) => this.inputPMChange("company", e.target.value)} />
      </Form.Label>
      </Form.Group>
      <Form.Group className = "form_row full" >
      <Form.Label >
      <span > Password: < /span>
      {/* <Form.Control type = "password" */}
      {/* name = "name" */}
      {/* value = {this.state.newPM.password} */}
      {/* onChange = { */}
      {/*   (e) => { */}
      {/*     this.inputPMChange("password", e.target.value) */}
      {/*   } */}
      {/* }/> */}
      <Password value={this.state.newPM.password} onChange={(e) => this.inputPMChange("password", e.target.value)} toggleMask />
      </Form.Label>
      </Form.Group>
      <Form.Group className = "form_row full" >
      <Form.Label >
      <span > Confirm Password: < /span>
      {/* <Form.Control type = "password" */}
      {/* name = "name" */}
      {/* value = {this.state.newPM.passwordConfirm} */}
      {/* onChange = { */}
      {/*   (e) => { */}
      {/*     this.inputPMChange("passwordConfirm", e.target.value) */}
      {/*   } */}
      {/* }/> */}
      <Password value={this.state.newPM.passwordConfirm} onChange={(e) => this.inputPMChange("passwordConfirm", e.target.value)} toggleMask />
      </Form.Label>
      </Form.Group>
      
      </Form>
      <div className = "formButtons" >
        <Button className={this.state.editPMState ? 'submitButton' : 'submitButton active'} variant="primary" type = "button" label = "ADD" onClick = {(e) => this.addProjectManagerSave(e)} />
        <Button className={this.state.editPMState ? 'submitButton active' : 'submitButton'} variant="primary" type = "button" label = "SAVE" onClick = {(e) => this.editProjectManagerSave(e)} />
        <Button severity= "danger" label="CANCEL" onClick = {(e) => this.closePMModel(e)} /> 
      </div>
      </div>
    </div>

    <Toast ref={this.toast} position="bottom-center"/>
    </div>

    
  )
  }
}

export default AddContractor;
