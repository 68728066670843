import React, {
  useState,
  useEffect
} from "react";
import {
  useAuth0,
  withAuthenticationRequired
} from "@auth0/auth0-react";
import Loading from "../components/Loading";
import AddMaterial from "../components/addMaterial";
import { Container, Row, Col } from "reactstrap";
import {
  Form,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Ripple } from 'primereact/ripple';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';

import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'

export const MaterialComponent = () => {
  const {
    user
  } = useAuth0();
  const [userFull, setUser] = useState({
    "_id": "",
    "name": ""
  });
  const [materials, setMaterials] = useState([]);
  const [newMaterialState, setnewMaterialState] = useState(false);
  const [newMaterial, setNewMaterial] = useState({
    "name": ""
  })

  const [newSettings, setnewSettings] = useState({
    state: false,
    material: {},
    id: ''
  });

  const addProjectManagerSave  = async (i) => {
  }

  function inputPMChange(type, value) {
    console.log(type)
    console.log(value)
    var currentnewPM = newMaterial;
    currentnewPM[type] = value;
    console.log(currentnewPM)
    setNewMaterial(currentnewPM)
  }
  // const [editJob, setEditJob] = useState({});
  // const [editJobState, setEditJobState] = useState(false);
  console.log(user)

  const handleDataUpdate = async (email) => {
    console.log(email)
      var response = await fetch("https://api.sandershylandtest.com/user/"+ email, {
        // mode:'no-cors',
       method: "GET",
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((response) => response.json())
      console.log(response)
      return response
  }

  const handleMaterialsUpdate = async (id) => {
    console.log(id)
      var response = await fetch("https://api.sandershylandtest.com/materials", {
        // mode:'no-cors',
       method: "GET",
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((response) => response.json())
      console.log(response)
      // setJobs(jobs)
      return response
  }

  const addMaterial = async (i) => {
    setnewMaterialState(true)
  }

  const deleteMaterial= async (material) => {
        console.log(material._id)
        var response = await fetch("https://api.sandershylandtest.com/material/delete/"+ material._id, {
        // mode:'no-cors',
         method: "POST",
         headers: {
           "Content-Type": "application/json",
         }
      })
      .then((response) => response.json())
      console.log(response)
      setMaterials(response);
      return response
  }

  const closeMaterialModel = async (i) => {
    setnewMaterialState(false)
  }

   const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        email: { value: null, matchMode: FilterMatchMode.CONTAINS },
        // jobNumber: { value: null, matchMode: FilterMatchMode.EQUALS },
        // invoice: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        // status: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    const [loading, setLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        console.log()

        _filters['global'].value = value;
        console.log(_filters)
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

      const renderHeader = () => {
        return (
            <div className="flex justify-space-between">
                <h2 className="text-xl text-900 font-bold">Materials:</h2>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
        );
    };
  const header = renderHeader();

  var modalOpenTemplate = (pm) => {
   
           return (
              <div className="button_flex">
              <Button icon="pi pi-file-edit" severity="warning" raised aria-label="Options" onClick={(e) => editMaterial(pm)} />
              <Button icon="pi pi-trash" raised severity="danger" aria-label="Options" onClick={(e) => deleteMaterial(pm)} />
             </div>
           );
  };

  const editMaterial = (pm) => {
        setnewSettings({
          state: true,
          material: pm,
          id: pm._id
        })
        console.log(pm)
    }


  useEffect(() => {
    let isMounted = true;

    handleMaterialsUpdate().then((data) => {
      console.log(data)
      if (isMounted) {
        setMaterials(data)
      }
        // handleJobsUpdate(data._id).then((data2) => {
          // setJobs(data2)
        // })
      isMounted = false;
    });

  }, [newMaterial]);

  return ( <>

    <Container>


        <AddMaterial newSettings={newSettings} />

        <DataTable header={header} value={materials} filters={filters} sortField="name" sortOrder={1} showGridlines stripedRows paginator rows={25} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }} globalFilterFields={['name']} emptyMessage="No contractors found.">
          <Column field="name" sortable header="Name"></Column>
          <Column body={modalOpenTemplate}></Column>
        </DataTable>

    </Container>
    </>
  );
};

export default withAuthenticationRequired(MaterialComponent, {
  onRedirecting: () => < Loading / > ,
});
