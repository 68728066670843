import React, {
  useState,
  useEffect
} from "react";
import {
  useAuth0,
  withAuthenticationRequired
} from "@auth0/auth0-react";
import Loading from "../components/Loading";
import AddPM from "../components/addPM";
import { Container, Row, Col } from "reactstrap";
import {
  Form,
  Button
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'

export const ProjectManagersComponent = () => {
  const {
    user
  } = useAuth0();
  const [userFull, setUser] = useState({
    "_id": "",
    "name": ""
  });
  const [projectManagers, setPms] = useState([]);
  const [newPMState, setnewPMState] = useState(false);
  const [newPM, setNewPM] = useState({
    "name": "",
    "email": "",
    "password": ""
  })

  const addProjectManagerSave  = async (i) => {
  }

  function inputPMChange(type, value) {
    console.log(type)
    console.log(value)
    var currentnewPM = newPM;
    currentnewPM[type] = value;
    console.log(currentnewPM)
    setNewPM(currentnewPM)
  }
  // const [editJob, setEditJob] = useState({});
  // const [editJobState, setEditJobState] = useState(false);
  console.log(user)

  const handleDataUpdate = async (email) => {
    console.log(email)
      var response = await fetch("https://api.sandershylandtest.com/user/"+ email, {
        // mode:'no-cors',
       method: "GET",
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((response) => response.json())
      console.log(response)
      return response
  }

  const handlePmsUpdate = async (id) => {
    console.log(id)
      var response = await fetch("https://api.sandershylandtest.com/project-managers/", {
        // mode:'no-cors',
       method: "GET",
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((response) => response.json())
      console.log(response)
      // setJobs(jobs)
      return response
  }

  const addProjectManager = async (i) => { 
    setnewPMState(true)
  }

  const editProjectManager = async (i) => { 

  }

  const deleteProjectManager = async (i) => { 
    
  }

  const closePmModel = async (i) => { 
    setnewPMState(false)
  }


  useEffect(() => {
//     let isMounted = true;
// 
//     handlePmsUpdate().then((data) => {
//       console.log(data)
//       if (isMounted) {
//         // setPms(data)
//       }
//         // handleJobsUpdate(data._id).then((data2) => {
//           // setJobs(data2)
//         // })
//       isMounted = false;
    // });

  }, [newPM]);

  return ( <>
    
    <Container>
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md>
          <h2>Project Managers:</h2>
        </Col>
      </Row>
    
    <AddPM />
    
    </Container>
    </>
  );
};

export default withAuthenticationRequired(ProjectManagersComponent, {
  onRedirecting: () => < Loading / > ,
});
