import React, {
  useState,
  useEffect,
  useRef
} from "react";
import {
  useAuth0,
  withAuthenticationRequired
} from "@auth0/auth0-react";
import Loading from "../components/Loading";
import AddContractor from "../components/addContractor";
import { Container, Row, Col } from "reactstrap";
import {
  Form,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Ripple } from 'primereact/ripple';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import { useHistory } from 'react-router-dom';

import 'primeicons/primeicons.css';  
import "primereact/resources/themes/lara-light-indigo/theme.css";     
import "primereact/resources/primereact.min.css";
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'

export const ProjectManagersComponent = () => {
  const {
    user
  } = useAuth0();
  const [userFull, setUser] = useState({
    "_id": "",
    "name": ""
  });

  const history = useHistory();
  const [projectManagers, setPms] = useState([]);
  // const newPMstate = useRef(false);
  const [newSettings, setnewSettings] = useState({
    state: false,
    pm: {},
    id: ''
  });
  // const [editPMState, seteditPMState] = useState(false);
  // const [editPM, seteditPM] = useState({});
  // const [newPMErrorState, setnewPMErrorState] = useState(false);

  const [newPM, setNewPM] = useState({
    "name": "",
    "email": "",
    "password": ""
  })

  const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        email: { value: null, matchMode: FilterMatchMode.CONTAINS },
        // jobNumber: { value: null, matchMode: FilterMatchMode.EQUALS },
        // invoice: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        // status: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    const [loading, setLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        console.log()

        _filters['global'].value = value;
        console.log(_filters)
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const editProjectManager = (pm) => {
        setnewSettings({
          state: true,
          pm: pm,
          id: pm._id
        })
        console.log(pm)
    }

    const renderHeader = () => {
        return (
            <div className="flex justify-space-between">
                <h2 className="text-xl text-900 font-bold">Contractors:</h2>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
        );
    };
  const header = renderHeader();

  var modalOpenTemplate = (pm) => {
   
           return (
              <div className="button_flex">
              <Button icon="pi pi-file-edit" severity="warning" raised aria-label="Options" onClick={(e) => editProjectManager(pm)} />
              <Button icon="pi pi-eye" raised severity="defautl" aria-label="Options" onClick={(e) => viewProjectManager(pm)} />
             </div>
           );
  };
  // const footer = `In total there are ${payments ? payments.length : 0} payments.`;

  const addProjectManagerSave  = async (i) => {
  }

  const viewProjectManager  = async (i) => {
      history.push('/contractor/'+i._id)
  }

  function inputPMChange(type, value) {
    console.log(type)
    console.log(value)
    var currentnewPM = newPM;
    currentnewPM[type] = value;
    console.log(currentnewPM)
    setNewPM(currentnewPM)
  }
  // const [editJob, setEditJob] = useState({});
  // const [editJobState, setEditJobState] = useState(false);
  console.log(user)

  const handleDataUpdate = async (email) => {
    console.log(email)
      var response = await fetch("https://api.sandershylandtest.com/user/"+ email, {
        // mode:'no-cors',
       method: "GET",
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((response) => response.json())
      console.log(response)
      return response
  }

  const handlePmsUpdate = async (id) => {
    console.log(id)
      var response = await fetch("https://api.sandershylandtest.com/all/contractors", {
        // mode:'no-cors',
       method: "GET",
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((response) => response.json())
      console.log(response)

      return response
  }

  const addProjectManager = async (i) => {
  }


  const deleteProjectManager = async (contractor) => {

    var response = await fetch("https://api.sandershylandtest.com/contractor-delete/"+ contractor._id, {
        // mode:'no-cors',
       method: "POST",
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((response) => response.json())
      console.log(response)
      setPms(response)
      return response


  }

  const closePmModel = async (i) => {
  }


  useEffect(() => {
    let isMounted = true;

    handlePmsUpdate().then((data) => {
      console.log(data)
      if (isMounted) {
        setPms(data)
      }
        // handleJobsUpdate(data._id).then((data2) => {
          // setJobs(data2)
        // })
      isMounted = false;
    });

  }, [newPM]);

  return ( <>

    <Container>


    <AddContractor newSettings={newSettings} />

    <Container className="mb-5">
        <DataTable header={header} value={projectManagers} filters={filters} sortMode="multiple" showGridlines stripedRows paginator rows={25} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }} globalFilterFields={['name', 'email', 'company']} emptyMessage="No contractors found.">
          <Column field="name" sortable header="Name"></Column>
          <Column field="email" sortable header="Email"></Column>
          <Column field="company" sortable header="Company"></Column>
          <Column body={modalOpenTemplate}></Column>
        </DataTable>
      </Container>
    </Container>
    </>
  );
};

export default withAuthenticationRequired(ProjectManagersComponent, {
  onRedirecting: () => < Loading / > ,
});
