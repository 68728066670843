import React, {
  useState,
  useEffect
} from "react";
import {
  useAuth0,
  withAuthenticationRequired
} from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { Container, Row, Col } from "reactstrap";
import {
  Form,
} from 'react-bootstrap';
import classNames from 'classnames';
import moment from "moment";
import { PDFDownloadLink,Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'
import logo_small from "../assets/sanders-hyland-logo-lg.png";
import roboto from "../assets/fonts/Roboto-Regular.ttf"
import robotoBold from "../assets/fonts/Roboto-Bold.ttf"
import DatePicker from "react-datepicker";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Ripple } from 'primereact/ripple';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';

import 'primeicons/primeicons.css';  
import "primereact/resources/themes/lara-light-indigo/theme.css";     
import "primereact/resources/primereact.min.css";

import "react-datepicker/dist/react-datepicker.css";
// Create styles

Font.register({ family: 'Roboto', src: roboto })
Font.register({ family: 'Roboto Bold', src: robotoBold })


export const AllPaymentsComponent = () => {
  const {
    user
  } = useAuth0();
  const [userFull, setUser] = useState({
    "_id": "",
    "name": ""
  });
  const [projectManagers, setPms] = useState([]);
  const [contractors, setContractors] = useState([]);
  const [liens_approved, setLiensApproved] = useState([]);
  const [liens_completed, setLiens] = useState([]);
  const [jobs, setJobs] = useState([]);
  // console.log(user)
  // console.log(contractors)
  // console.log(projectManagers)
  const [ascending, setAscending] = useState(false)
  const [currentSort, setCurrentSort] = useState("")
  var date = new Date();
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [contractor, setContractor] = useState('')
  const [project_manager, setProjectManager] = useState('')
  const [jobNumber, setJobNumber] = useState('')
  const history = useHistory();


  const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        contractorCompany: { value: null, matchMode: FilterMatchMode.EQUALS },
        projectManager: { value: null, matchMode: FilterMatchMode.EQUALS },
        value: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        jobNumber: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        invoice: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        dateFormat: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
    });
    const [loading, setLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        // console.log()

        _filters['global'].value = value;
        // console.log(_filters)
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const getSeverity = (lien) => {
        // console.log(lien.status)
        switch (lien.status) {

            case 'started':
                return 'primary';

            case 'unsubmitted':
                return 'primary';

            case 'signed':
                return 'primary';

            case 'in review':
                return 'info';

            case 'rejected':
                return 'danger';

            case 'approved':
                return 'success';

            case 'completed':
                return 'success';
                
            default:
                return null;
        }
    };

      const formatDate = (value) => {
        console.log(value)
        var date = new Date(value);
        console.log(date)
        var newValue = date.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });

        var newTime = date.toLocaleTimeString('en-US');
        console.log(newTime)
        return newValue + " " + newTime;
    }

    const formatCurrency = (value) => {
        console.log(value)
        if (value) {
          return "$"+ value.toFixed(2)
          // return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        } else {
          value = 0;
          return "$"+ value.toFixed(2)
          // return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        }
        
    }

const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.date);
    }

    const balanceBodyTemplate = (rowData) => {
        return formatCurrency(rowData.value);
    }

    const renderHeader = () => {
        return (
            <div className="flex justify-space-between">
                <h2 className="text-xl text-900 font-bold">Approved Liens for Processing this Week:</h2>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
        );
    };
  const header = renderHeader();
  const footer = `In total there are ${liens_approved ? liens_approved.length : 0} payments.`;

      const renderHeaderCompleted = () => {
        return (
            <div className="flex justify-space-between">
                <h2 className="text-xl text-900 font-bold">Completed Liens:</h2>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
        );
    };
  const header_completed = renderHeaderCompleted();
  const footer_completed = `In total there are ${liens_completed ? liens_completed.length : 0} payments.`;
  const sortPayments = async (column) => {
    // if (ascending === false) {
    //   setAscending(true)
    // } else {
    //   setAscending(false)
    // }
    // setCurrentSort(column)
    // console.log(column)
    //  const response = await fetch(
    //   "https://api.sandershylandtest.com/liens-sort-admin/" + column + "/" + ascending
    // ).then((response) => response.json());
    // console.log(response)
    // setLiens(response.reverse())
  }

    const statusBodyTemplate = (lien) => {
    return <Tag value={lien.status} severity={getSeverity(lien)}></Tag>;
  };

  var modalOpenTemplate = (lien) => {
    // console.log(lien.status)
        if (lien.status === "started") {
           return (
              <div className="button_flex">
              <Button icon="pi pi-file-edit" raised aria-label="Options" onClick={(e) => goToPDF(lien)} />
              {/* <Button icon="pi pi-trash" raised severity="danger" aria-label="Options" onClick={(e) => deleteApp(lien)} /> */}
             </div>
           );
       } else if (lien.status === "unsubmitted") {
          return (
              <div className="button_flex">
              <Button icon="pi pi-send"  raised aria-label="Options" onClick={(e) => goToPDF(lien)} />
              {/* <Button icon="pi pi-trash" raised severity="danger" aria-label="Options" onClick={(e) => deleteApp(lien)} /> */}
             </div>
           );
        } else if (lien.status === "approved") {
          return (
              <div className="button_flex">
              <Button icon="pi pi-eye" outlined raised severity="success" aria-label="Options" onClick={(e) => goToPDF(lien)} />
             </div>
           );
       } else if (lien.status === "rejected") {

          return (
              <div className="button_flex">
              <Button icon="pi pi-eye" raised severity="danger" aria-label="Options" onClick={(e) => goToPDF(lien)} />
             </div>
           );
       } else if ( lien.status === "signed") {
          return (
              <div className="button_flex">
              <Button icon="pi pi-send"  raised aria-label="Options" onClick={(e) => goToPDF(lien)} />
             </div>
           );
       } else if ( lien.status === "completed") {
         return (
              <div className="button_flex">
              <Button icon="pi pi-eye" outlined raised severity="info" aria-label="Options" onClick={(e) => goToPDF(lien)} />
             </div>
           );
        } else if ( lien.status === "in review") {
            return (
              <div className="button_flex">
              {/*  */}
              <a href={"/approve/" +lien._id}><Button icon="pi pi-check-circle" outlined raised severity="info" aria-label="Options" /></a>
              {/* <a className={attachment_status ? 'attachment active' : 'attachment'} variant="success" href={"https://sanders-hyland.s3.amazonaws.com/uploads/" +lien.attachment_url} target="_Blank">View Attachment</a> */}
             </div>
           );
         } 
  };

  const termFilterSubmit = async (term) => {
    const filters = {
      "startDate": startDate,
      "endDate": endDate,
      "contractor": contractor,
      "project_manager":project_manager,
      "jobNumber": jobNumber
    }
    // console.log(filters)
    var response = await fetch("https://api.sandershylandtest.com/liens/filter/", {
         method: "POST",
         // mode:'no-cors',
         headers: {
           "Content-Type": "application/json",
         },
         body: JSON.stringify(filters),
      })
      .then((response) => response.json())
      // console.log(response)
      setLiens(response.reverse())
  }

  const filterClear = async () => {
    if (startDate != "") {
      setStartDate('')
    }
    if (endDate != "") {
      setEndDate('')
    }
    if (jobNumber != "") {
      setJobNumber('')
    }
    if (contractor != "") {
      setContractor('')
    }
    if (project_manager != "") {
      setProjectManager('')
    }
    // console.log(project_manager)
    handleLiensUpdate().then((data2) => {
      setLiens(data2.reverse())
      handlePmsUpdate().then((data3) => {
        // console.log(data3)
        setPms(data3)

        handleContractorsUpdate().then((data4) => {
          // console.log(data4)
          setContractors(data4)

          handleJobsUpdate().then((data5) => {
            setJobs(data5)
          })
        })
      })
    })
  }

  const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
    };

  const [statuses] = useState(['approved', 'completed']);

  const statusItemTemplate = (option) => {
        return <Tag value={option} severity={getSeverity(option)} />;
    };

  const statusRowFilterTemplate = (options) => {
        return (
            <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate} placeholder="Select One" className="p-column-filter" />
        );
    };

    const pmItemTemplate = (option) => {
          console.log(option)
          if (option != undefined && option != "undefined")
            {
              return <Tag value={option} />;
            } else {
              return;
            }
        
    };

  const pmRowFilterTemplate = (options) => {
        console.log(projectManagers)
        return (
            <Dropdown value={options.value} options={projectManagers} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={pmItemTemplate} placeholder="Select One" className="p-column-filter"  />
        );
    };

    //  const contractorsItemTemplate = (option) => {
    //     return <Tag value={option} />;
    // };

  // const contractorsRowFilterTemplate = (options) => {
  //       console.log(contractors)
  //       return (
  //           <Dropdown value={options.value} options={contractors} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={contractorsItemTemplate} placeholder="Select One" className="p-column-filter"  />
  //       );
  //   };

   const completePayment = async (item) => {
    var response = await fetch("https://api.sandershylandtest.com/lien/complete/"+ item, {
         method: "POST",
         // mode:'no-cors',
         headers: {
           "Content-Type": "application/json",
         }
      })
      .then((response) => response.json())
       handleJobsUpdate(userFull._id).then((data2) => {
          setLiens(data2)
        })
  }


  const handleDataUpdate = async (email) => {
    // console.log(email)
      var response = await fetch("https://api.sandershylandtest.com/user/"+ email, {
        // mode:'no-cors',
       method: "GET",
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((response) => response.json())
      // console.log(response)
      return response
  }

  const handleLiensUpdate = async (id) => {
    // console.log(id)
      var liens_completed = await fetch("https://api.sandershylandtest.com/liens-all-approved", {
        // mode:'no-cors',
       method: "GET",
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((liens_completed) => liens_completed.json())
      console.log(liens_completed)
      setLiens(liens_completed)
      return liens_completed
  }

  const handleLiensApprovedUpdate = async (id) => {
    // console.log(id)
      var liens_approved = await fetch("https://api.sandershylandtest.com/this-week-approved/", {
        // mode:'no-cors',
       method: "GET",
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((liens_approved) => liens_approved.json())
      console.log(liens_approved)
      setLiensApproved(liens_approved)
      return liens_approved
  }

  const handleJobsUpdate = async (id) => {
    // console.log(id)
      var response = await fetch("https://api.sandershylandtest.com/jobs", {
        // mode:'no-cors',
       method: "GET",
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((response) => response.json())
      // console.log(response)
      setJobs(response)
      return response
  }

  const goToPDF = async (item) => {
    // console.log(item)
    history.push('/pdf/'+item._id)
  }

  const handleContractorsUpdate = async () => {
    var response = await fetch("https://api.sandershylandtest.com/all/contractors", {
     method: "GET",
     // mode:'no-cors',
     headers: {
       "Content-Type": "application/json",
     }
  })
  .then((response) => response.json())
      // console.log(response)  
      var alphaContractors = response.sort((a, b) => {
        if(a.company) {
          return a.company.localeCompare(b.company)
        } else {
          return
        }
        
      })
      return alphaContractors
  }

  const handlePmsUpdate = async () => {
      var response = await fetch("https://api.sandershylandtest.com/project-managers/", {
        // mode:'no-cors',
       method: "GET",
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((response) => response.json())
      // console.log(response)
      console.log(projectManagers)
      return response
  }

  useEffect(() => {
    let isMounted = true;
    // console.log(userFull._id)
    // if (userFull._id != "") {
    // console.log(user.email)
    handleDataUpdate(user.email).then((data) => {
        setUser(data)
        console.log("STAGE 1")
        handleLiensUpdate(data._id).then((data2) => {
          setLiens(data2.reverse())
          console.log(data2)
          console.log("STAGE 2")
          handleLiensApprovedUpdate(data._id).then((data_approved) => {
            setLiensApproved(data_approved.reverse())
            console.log(data_approved)
            console.log("STAGE 3")
            handlePmsUpdate().then((data3) => {
              console.log(data3)
              var permittedValues = [];
               for (var i = 0; i < data3.length; i++){
                  permittedValues[i] = data3[i]["name"];
                  if (i === data3.length - 1) {
                    setPms(permittedValues)
                    handleContractorsUpdate().then((data4) => {
                      console.log(data4)
                      var permittedValues2 = [];
                     for (var i = 0; i < data4.length; i++){
                        permittedValues2[i] = data4[i]["company"];
                        if (i === data4.length - 1) {
                        setContractors(permittedValues2)

                        handleJobsUpdate(data._id).then((data5) => {
                          setJobs(data5)
                        })
                      }
                    }
                    })
                  }
               }
            })
          })
        })
    });
    return () => {
      isMounted = false;
    };
    // }
  }, []);

  return ( <>

    <div className="Jobs">
    <Container className="mb-5">
      <DataTable header={header}  footer={footer} value={liens_approved} filters={filters} multiSortMeta={["status"]} filterDisplay="row" sortMode="multiple" showGridlines stripedRows paginator rows={25} rowsPerPageOptions={[5, 10, 25, 50, 100]} tableStyle={{ minWidth: '50rem' }} globalFilterFields={['invoice', 'value', 'contractorCompany', 'projectManager', 'status', 'jobNumber']} emptyMessage="No Payments found.">
        <Column field="date" header="Date" sortable filterField="date" dataType="date" body={dateBodyTemplate} />
        <Column field="value" sortable header="Value" dataType="numeric" body={balanceBodyTemplate}></Column>
        <Column field="jobNumber" sortable header="Job Number"></Column>
        <Column field="invoice" sortable header="Invoice"></Column>
        <Column field="contractorCompany" sortable header="Contractor"></Column>
        <Column field="projectManager" sortable header="Project Manager"></Column>
        <Column field="status" body={statusBodyTemplate} sortable header="Status" filter filterElement={statusRowFilterTemplate}></Column>
        <Column body={modalOpenTemplate}></Column>
      </DataTable>
    </Container>

    <Container className="mb-5">
      <DataTable header={header_completed}  footer={footer_completed} value={liens_completed} filters={filters} multiSortMeta={["status"]} filterDisplay="row" sortMode="multiple" showGridlines stripedRows paginator rows={25} rowsPerPageOptions={[5, 10, 25, 50, 100]} tableStyle={{ minWidth: '50rem' }} globalFilterFields={['invoice', 'value', 'contractorCompany', 'projectManager', 'status', 'jobNumber']} emptyMessage="No Payments found.">
        <Column field="date" header="Date" sortable filterField="date" dataType="date" body={dateBodyTemplate} filter filterElement={dateFilterTemplate} />
        <Column field="value" sortable header="Value" dataType="numeric" body={balanceBodyTemplate}></Column>
        <Column field="jobNumber" sortable header="Job Number"></Column>
        <Column field="invoice" sortable header="Invoice"></Column>
        <Column field="contractorCompany" sortable header="Contractor"></Column>
        <Column field="projectManager" sortable header="Project Manager"></Column>
        <Column field="status" body={statusBodyTemplate} sortable header="Status" filter filterElement={statusRowFilterTemplate}></Column>
        <Column body={modalOpenTemplate}></Column>
      </DataTable>
    </Container>
    </div>



    </>
  );
};

export default withAuthenticationRequired(AllPaymentsComponent, {
  onRedirecting: () => < Loading / > ,
});
