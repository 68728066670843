import React, { Component } from "react";
import {
  useAuth0,
  withAuthenticationRequired
} from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { Container, Row, Col } from "reactstrap";
import {
  Form
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'primereact/button';
import { faTrash, faEdit, faEye } from '@fortawesome/free-solid-svg-icons'
import styles from './styles.module.css';

const job_total = 0;

class AddMaterial extends Component {


  constructor(props) {
    console.log(props)
    super(props);
    const user = this.props.user;
    this.state = {
      "userFull": {
        "_id": "",
        "name": ""
      },
      "newMaterial": {
        "name": ""
      },
      "newMaterialState": false,
      "editMaterialState": false,
      "editMaterialID": null,
      "materials": []
    }
    this.addmaterial = this.addmaterial.bind(this);
    this.closeMaterialModel = this.closeMaterialModel.bind(this);
    this.editmaterial = this.editmaterial.bind(this);
    this.addmaterialsave = this.addmaterialsave.bind(this);
    this.deletematerial = this.deletematerial.bind(this);

  };

  addmaterial() {
    this.setState({
      newMaterialState: true
    });
  }

  editmaterial(index) {
    var currentMaterialEdit = this.state.materials[index];
    this.setState({
      newMaterialState: true,
      newMaterial: currentMaterialEdit
    });
    // console.log(this.state.jobs[index])
    // var clearform = this.state.form;
    // clearform.project_manager_id = this.state.jobs[index].project_manager_id;
    // clearform.project_manager_name = this.state.jobs[index].project_manager_name;
    // clearform.number = this.state.jobs[index].number;
    // clearform.name = this.state.jobs[index].name;
    // clearform.location = this.state.jobs[index].location;
    // clearform.contractors = this.state.jobs[index].contractors;
    // this.setState({
    //   form: clearform,
    //   newJobState: true,
    //   editJobState: true,
    //   editJobID: this.state.jobs[index]._id
    // });
  }

  async addmaterialsave() {
    console.log(this.state.newMaterial)
    var materials = await fetch("https://api.sandershylandtest.com/material/add", {
           method: "POST",
           // mode:'no-cors',
           headers: {
             "Content-Type": "application/json",
           },
           body: JSON.stringify(this.state.newMaterial),
        })
        .then((materials) => materials.json())
        .catch(function(error) {
          console.log(error);
        });
        console.log(materials)
        var form = {
                "name": ""
          };
        this.setState({
          newMaterialState: false,
          editMaterialState: false,
          newMaterial: form,
          materials: materials
        });
      }

  async deletematerial(id) {

    var response = await fetch("https://api.sandershylandtest.com/material/delete/"+ id, {
        // mode:'no-cors',
       method: "POST",
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((response) => response.json())
      console.log(response)
      this.setState({
          materials: response
        });
      return response

  }

  closeMaterialModel() {
    var clearform = this.state.newMaterial;
    clearform = [
          {
            "email": "",
            "name": "",
            "password": "",
            "phone": ""
          }
        ];

    this.setState({
      newMaterialState: false,
      newMaterial: clearform
    });
  }

  inputMaterialChange(name, value) {
    console.log(name)
    console.log(value)
    const formCurrent = this.state.newMaterial;
    formCurrent[name] = value;
    this.setState({
      newMaterial: formCurrent
    });
    console.log(this.state.newMaterial)
  }

  async handleMaterialsUpdate(id) {
    console.log(id)
    var response = await fetch("https://api.sandershylandtest.com/materials", {
        // mode:'no-cors',
       method: "GET",
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((response) => response.json())
      console.log(response)

      return response

  }



  componentDidMount() {
    var state_check;
    if (state_check != this.state) {
      this.handleMaterialsUpdate().then((data) => {
        console.log(data)
        this.setState({
          materials: data
        });
        var state_check = this.state;
      })
    }



  }


  componentDidUpdate(prevProps, prevState, snapshot, nextProps) {
    console.log(prevProps.newPMState)
    console.log(this.props.newPMState)
    if(prevProps.newSettings !== this.props.newSettings) {
      if ( this.props.newSettings )
        this.setState({
          editMaterialState: this.props.newSettings.state,
          newMaterialState: this.props.newSettings.state,
          newMaterial: this.props.newSettings.material,
          editMaterialID: this.props.newSettings.id
        });
    }
  }

  render () {
    return(
      <div>
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md>
          <Button className="addMaterial" s onClick = {(e) => {this.addmaterial()}}>ADD MATERIAL</Button>
        </Col>
      </Row>
    <div className = {this.state.newMaterialState ? 'newContractorFormHolder active' : 'newContractorFormHolder'}>
      <div className="newContractorForm">

      <Form>
      <div className="modelTitle">Add a New Material</div>
      <Form.Group className = "form_row full" >
      <Form.Label >
      <span > Name: < /span>
      <Form.Control type = "text"
      name = "name"
      value = {this.state.newMaterial.name}
      onChange = {
        (e) => {
          this.inputMaterialChange("name", e.target.value)
        }
      }/>
      </Form.Label>
      </Form.Group>
      <div className = "formButtons" >
        <Button className="submitButton active" severity = "primary" type = "button" onClick = {() => this.addmaterialsave()} >SAVE </Button>
        <Button severity = "danger" onClick = {() => this.closeMaterialModel()} > CANCEL </Button>
      </div>
      </Form>
      </div>
    </div>

    {/* <div className="projectmanagers_table"> */}
    {/*   {this.state.materials.map((material, i) => { */}
    {/*     return ( */}
    {/*     <div className="projectmanager" key={i}> */}
    {/*       <div className="pm_name">{material.name}</div> */}
    {/*       <div className="pm_email">{material.email}</div> */}
    {/*       <div className="pm_button"> */}
    {/*         <button className="edit" onClick = {(e) => {this.editmaterial(i)}}>EDIT</button> */}
    {/*       </div> */}
    {/*        <div className="pm_button"> */}
    {/*         <button className="delete" onClick = {(e) => {this.deletematerial(material._id)}}>DELETE</button> */}
    {/*       </div> */}
    {/*     </div> */}
    {/*     ) */}
    {/*   })} */}
    {/* </div> */}
    </div>
  )
  }
}

export default AddMaterial;
