import React, { Component } from "react";
import {
  useAuth0,
  withAuthenticationRequired
} from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { Container, Row, Col } from "reactstrap";
import {
  Form,
  Button
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faTrash, faEdit, faEye } from '@fortawesome/free-solid-svg-icons'
import styles from './styles.module.css';

const job_total = 0;

class AddPM extends Component {


  constructor(props) {
    console.log(props)
    super(props);
    const user = this.props.user;
    this.state = {
      "userFull": {
        "_id": "",
        "name": ""
      },
      "newPM": {
        "name": "",
        "password": "",
        "company": "",
        "email": ""
      },
      "newPMState": false,
      "editPMState": false,
      "editPMID": null,
      "projectManagers": []
    }
    this.addProjectManager = this.addProjectManager.bind(this);
    this.closePMModel = this.closePMModel.bind(this);
    this.editProjectManager = this.editProjectManager.bind(this);
    this.addProjectManagerSave = this.addProjectManagerSave.bind(this);
    this.deleteProjectManager = this.deleteProjectManager.bind(this);

  };

  addProjectManager() {
    this.setState({
      newPMState: true
    });
  }

  editProjectManager(index) {
    var currentPMEdit = this.state.projectManagers[index];
    this.setState({
      newPMState: true,
      newPM: currentPMEdit
    });
    // console.log(this.state.jobs[index])
    // var clearform = this.state.form;
    // clearform.project_manager_id = this.state.jobs[index].project_manager_id;
    // clearform.project_manager_name = this.state.jobs[index].project_manager_name;
    // clearform.number = this.state.jobs[index].number;
    // clearform.name = this.state.jobs[index].name;
    // clearform.location = this.state.jobs[index].location;
    // clearform.contractors = this.state.jobs[index].contractors;
    // this.setState({
    //   form: clearform,
    //   newJobState: true,
    //   editJobState: true,
    //   editJobID: this.state.jobs[index]._id
    // });
  }

  async addProjectManagerSave() {
    console.log(this.state.newPM)
    var newPM = await fetch("https://api.sandershylandtest.com/project-manager-add", {
           method: "POST",
           // mode:'no-cors',
           headers: {
             "Content-Type": "application/json",
           },
           body: JSON.stringify(this.state.newPM),
        })
        .then((newPM) => newPM.json())
        .catch(function(error) {
          console.log(error);
        });
        console.log(newPM)
        var form = {
                "email": "",
                "name": "",
                "password": "",
                "phone": ""
          };
        this.setState({
          newPMState: false,
          editPMState: false,
          newPM: form,
        });
      }

  async deleteProjectManager(id) {

    var response = await fetch("https://api.sandershylandtest.com/project-manager-delete/"+ id, {
        // mode:'no-cors',
       method: "POST",
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((response) => response.json())
      console.log(response)
      this.setState({
          projectManagers: response
        });
      return response

  }

  closePMModel() {
    var clearform = this.state.newPM;
    clearform = [
          {
            "email": "",
            "name": "",
            "password": "",
            "phone": ""
          }
        ];

    this.setState({
      newPMState: false,
      newPM: clearform
    });
  }

  inputPMChange(name, value) {
    console.log(name)
    console.log(value)
    const formCurrent = this.state.newPM;
    formCurrent[name] = value;
    this.setState({
      newPM: formCurrent
    });
    console.log(this.state.newPM)
  }

  async handlePmsUpdate(id) {
    console.log(id)
    var response = await fetch("https://api.sandershylandtest.com/project-managers/", {
        // mode:'no-cors',
       method: "GET",
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((response) => response.json())
      console.log(response)

      return response
      
  }



  componentDidMount() {
    var state_check;
    if (state_check != this.state) {
      this.handlePmsUpdate().then((data) => {
        console.log(data)
        this.setState({
          projectManagers: data
        });
        var state_check = this.state;
      })
    }



  }


  componentDidUpdate(prevProps, prevState, snapshot) {

  }

  render () {
    return(
      <div>
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md>
          <button className="addPM" onClick = {(e) => {this.addProjectManager()}}>ADD PROJECT MANAGER</button>
        </Col>
      </Row>
    <div className = {this.state.newPMState ? 'newContractorFormHolder active' : 'newContractorFormHolder'}>
      <div className="newContractorForm">

      <Form>
      <div className="modelTitle">Add a New Project Manager</div>
      <Form.Group className = "form_row full" >
      <Form.Label >
      <span > Name: < /span>
      <Form.Control type = "text"
      name = "name"
      value = {this.state.newPM.name}
      onChange = {
        (e) => {
          this.inputPMChange("name", e.target.value)
        }
      }/>
      </Form.Label>
      </Form.Group>
      <Form.Group className = "form_row full" >
      <Form.Label >
      <span > Email: < /span>
      <Form.Control type = "email"
      name = "name"
      value = {this.state.newPM.email}
      onChange = {
        (e) => {
          this.inputPMChange("email", e.target.value)
        }
      }/>
      </Form.Label>
      </Form.Group>
      <Form.Group className = "form_row full" >
      <Form.Label >
      <span > Phone: < /span>
      <Form.Control type = "phone"
      name = "name"
      value = {this.state.newPM.phone}
      onChange = {
        (e) => {
          this.inputPMChange("phone", e.target.value)
        }
      }/>
      </Form.Label>
      </Form.Group>
      <Form.Group className = "form_row full" >
      <Form.Label >
      <span > Password: < /span>
      <Form.Control type = "password"
      name = "name"
      value = {this.state.newPM.password}
      onChange = {
        (e) => {
          this.inputPMChange("password", e.target.value)
        }
      }/>
      </Form.Label>
      </Form.Group>
      <div className = "formButtons" >
        <Button className="submitButton active" variant = "primary" type = "button" onClick = {() => this.addProjectManagerSave()} >SAVE </Button>
        <Button variant = "danger" onClick = {() => this.closePMModel()} > CANCEL </Button>
      </div>
      </Form>
      </div>
    </div>

    <div className="projectmanagers_table">
      {this.state.projectManagers.map((projectManager, i) => {
        return (
        <div className="projectmanager" key={i}>
          <div className="pm_name">{projectManager.name}</div>
          <div className="pm_email">{projectManager.email}</div>
          <div className="pm_button">
            <button className="edit" onClick = {(e) => {this.editProjectManager(i)}}>EDIT</button>
          </div>
           <div className="pm_button">
            <button className="delete" onClick = {(e) => {this.deleteProjectManager(projectManager._id)}}>DELETE</button>
          </div>
        </div>
        )
      })}
    </div>
    </div>
  )
  }
}

export default AddPM;


