import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import {Alert } from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../config";
import Loading from "../components/Loading";
import Signature from "../components/signature";
import { useHistory, useLocation} from 'react-router-dom';
import ReactDOM from 'react-dom';
import { PDFDownloadLink, PDFViewer, BlobProvider } from '@react-pdf/renderer';
import ReactPDF ,{ Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import SignatureCanvas from 'react-signature-canvas';
import moment from "moment";

import { Container, Row, Col } from "reactstrap";
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Ripple } from 'primereact/ripple';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';

import logo_small from "../assets/sanders-hyland-logo-lg.png";
import roboto from "../assets/fonts/Roboto-Regular.ttf"
import robotoBold from "../assets/fonts/Roboto-Bold.ttf"



// Create Document Component
export const PdfComponent = () => {
	const {
    user
  } = useAuth0();
  const [userFull, setUser] = useState({
    "_id": "",
    "name": ""
  });
  const history = useHistory();
  const [contractor, setContractor] = useState({});
  const [liens, setLiens] = useState([]);
  const [jobs, setJobs] = useState([]);
  const location = useLocation();
  const id = location.pathname.replace('/contractor/', '')
  console.log('id', id);


  const handleUserUpdate = async (email) => {
    console.log(email)
      var response = await fetch("https://api.sandershylandtest.com/user/"+ email, {
        // mode:'no-cors',
       method: "GET",
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((response) => response.json())
      console.log(response)
      return response
  }

  const handleContractorUpdate = async (email) => {
    console.log(userFull)
      var response = await fetch("https://api.sandershylandtest.com/single_contractor/"+ id, {
        // mode:'no-cors',
       method: "GET",
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((response) => response.json())
      console.log(response)
      return response
  }

  const handleJobsUpdate = async (pm_id) => {
    console.log(pm_id)
    var response = await fetch("https://api.sandershylandtest.com/jobs-pm-contractor/"+ pm_id +"/"+ id, {
       method: "GET",
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((response) => response.json())
    // setJobs(response)
    console.log(response)
    console.log(response.length)
          // console.log(contractors)
      // setContractors(jobs)
    //   var alphaContractors = contractors.sort((a, b) => a.email.localeCompare(b.email))
    //   setContractors(alphaContractors);
    // // console.log(currentLiens)
    //   // console.log(user)
    //   var contractors_list = contractors;
      // console.log(contractors_list)
      // currentForm.project_manager_id = user._id;
      // currentForm.project_manager_name = user.name;
      // console.log(jobs)
      setJobs(response)
    if (response.length > 0) {
      response.forEach(function(job, i) {
        const job_index = i;
        job.total = 0;
        job.trtd = 0;
        // job.contractors.map((contractor, x) => {
          // console.log(contractor)
          const contractor_index = job.contractors.findIndex(contractor_item => {
            // console.log(contractor_item)
            return contractor_item.id === id;
          });
          console.log(contractor_index)
          if (liens.length > 0){
          const response_liens = liens.filter(lien => {
            return lien.contractor_id === id && lien.jobNumber === job.number;
          });

          console.log(response_liens)
          const data = response_liens[0];
          // console.log(jobs[job_index].contractors[contractor_index])
          const contractor_info = contractor;
          // console.log(contractor_info)
          if (response_liens.length > 0){
            var job_contractor_total = 0;
  
            response_liens.forEach(function(lien, z) {
              if (lien.status === "approved" || lien.status === "completed") {
                 console.log(lien)
                  if (lien.lineItems_manHours_total) {} else {
                    lien.lineItems_manHours_total = 0
                  }
                  if (lien.lineItemsTotal) {} else {
                    lien.lineItemsTotal = 0
                  }
                  if (lien.lineItems_other_total) {} else {
                    lien.lineItems_other_total = 0
                  }
                  var job_total = ((Number(lien.lineItems_manHours_total) + Number(lien.lineItemsTotal)+ Number(lien.lineItems_other_total)) - ((Number(lien.lineItems_manHours_total) + Number(lien.lineItemsTotal) + Number(lien.lineItems_other_total)) * (response[job_index].contractors[contractor_index].retention/100)));
                  console.log(job_total)
                  job_contractor_total += job_total;
                  // console.log(job_contractor_total)
              }
             
            })

            response[job_index].job_total = "$" + ((Number(data.lineItems_manHours_total) + Number(data.lineItemsTotal)+ Number(data.lineItems_other_total)) - ((Number(data.lineItems_manHours_total) + Number(data.lineItemsTotal)+ Number(data.lineItems_other_total)) * (response[job_index].contractors[contractor_index].retention/100))).toFixed(2).toString()
            response[job_index].status = data.status.toUpperCase();
            response[job_index].grand_total = "$" + job_contractor_total.toFixed(2).toString();
            response[job_index].total_retention = "$" + (job_contractor_total * (data.retention/100)).toFixed(2).toString();
            job.total += job_contractor_total;
            job.trtd += (job_contractor_total * (data.retention/100));
            // if (x === job.contractors.length -1) {
              if (i === response.length -1) {
                console.log(response)
                setJobs(response)
                setUser(user)
                return jobs
              }
            // }
          } else {
            var job_contractor_total = 0;
            if (contractor_info.length > 0) {
              response[job_index].contractors[contractor_index].company = contractor_info[0].company;
            }
            
            response[job_index].job_total = "No Total Yet";
            response[job_index].status = "UNSUBMITTED";
            response[job_index].grand_total = 0.00;
            response[job_index].total_retention = 0.00;
            job.total += 0;
            job.trtd += 0;
              if (i === response.length -1) {
                console.log(response)
                setJobs(response)
                setUser(user)
                return jobs
              }
                      }
        }
        // })

      })
    } else {
      return response
    }
      
    
  }


  const handleDataUpdate = async (pm_id) => {
    console.log(userFull)
      var response = await fetch("https://api.sandershylandtest.com/liens_pm_contractor/" + pm_id + "/"+ id, {
        // mode:'no-cors',
       method: "GET",
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((response) => response.json())
      console.log(response)
      return response
  }

    // const [filters, setFilters] = useState({
    //     global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    //     contractor: { value: null, matchMode: FilterMatchMode.EQUALS },
    //     value: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    //     jobNumber: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    //     invoice: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    //     status: { value: null, matchMode: FilterMatchMode.EQUALS },
    // });
    const [loading, setLoading] = useState(true);
//     const [globalFilterValue, setGlobalFilterValue] = useState('');
// 
//     const onGlobalFilterChange = (e) => {
//         const value = e.target.value;
//         let _filters = { ...filters };
//         console.log()
// 
//         _filters['global'].value = value;
//         console.log(_filters)
//         setFilters(_filters);
//         setGlobalFilterValue(value);
//     };

    const getSeverity = (status) => {
        console.log(status)
        var trueStatus = status;
        if (trueStatus !=undefined) {
          trueStatus = status.toLowerCase()
          switch (trueStatus) {

            case 'started':
                return 'primary';

            case 'unsubmitted':
                return 'primary';

            case 'signed':
                return 'primary';

            case 'in review':
                return 'info';

            case 'rejected':
                return 'danger';

            case 'approved':
                return 'success';

            case 'completed':
                return 'success';
                
            default:
                return null;
        }
        } else {
          switch (trueStatus) {

            case 'started':
                return 'primary';

            case 'unsubmitted':
                return 'primary';

            case 'signed':
                return 'primary';

            case 'in review':
                return 'info';

            case 'rejected':
                return 'danger';

            case 'approved':
                return 'success';

            case 'completed':
                return 'success';
                
            default:
                return null;
        }
        }
        
        
    };

  const statusBodyTemplate = (job) => {
    return <Tag value={job.status} severity={getSeverity(job.status)}></Tag>;
  };

  var modalOpenTemplate = (lien) => {
    // console.log(lien.status)
        if (lien.status === "started") {
           return (
              <div className="button_flex">
              <Button icon="pi pi-file-edit" raised aria-label="Options" onClick={(e) => goToPDF(lien)} />
              {/* <Button icon="pi pi-trash" raised severity="danger" aria-label="Options" onClick={(e) => deleteApp(lien)} /> */}
             </div>
           );
       } else if (lien.status === "unsubmitted") {
          return (
              <div className="button_flex">
              <Button icon="pi pi-send"  raised aria-label="Options" onClick={(e) => goToPDF(lien)} />
              {/* <Button icon="pi pi-trash" raised severity="danger" aria-label="Options" onClick={(e) => deleteApp(lien)} /> */}
             </div>
           );
        } else if (lien.status === "approved") {
          return (
              <div className="button_flex">
              <Button icon="pi pi-eye" outlined raised severity="success" aria-label="Options" onClick={(e) => goToPDF(lien)} />
             </div>
           );
       } else if (lien.status === "rejected") {

          return (
              <div className="button_flex">
              <Button icon="pi pi-eye" raised severity="danger" aria-label="Options" onClick={(e) => goToPDF(lien)} />
             </div>
           );
       } else if ( lien.status === "signed") {
          return (
              <div className="button_flex">
              <Button icon="pi pi-send"  raised aria-label="Options" onClick={(e) => goToPDF(lien)} />
             </div>
           );
       } else if ( lien.status === "completed") {
         return (
              <div className="button_flex">
              <Button icon="pi pi-eye" outlined raised severity="info" aria-label="Options" onClick={(e) => goToPDF(lien)} />
             </div>
           );
        } else if ( lien.status === "in review") {
            return (
              <div className="button_flex">
              {/*  */}
              <a href={"/approve/" +lien._id}><Button icon="pi pi-check-circle" outlined raised severity="info" aria-label="Options" /></a>
              {/* <a className={attachment_status ? 'attachment active' : 'attachment'} variant="success" href={"https://sanders-hyland.s3.amazonaws.com/uploads/" +lien.attachment_url} target="_Blank">View Attachment</a> */}
             </div>
           );
         } 
  };

  const goToPDF = async (item) => {
    // console.log(item)
    history.push('/pdf/'+item._id)
  }

  const formatDate = (value) => {
        // console.log(value)
        var date = new Date(value);
        // console.log(date)
        var newValue = date.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
        // console.log(newValue)
        return newValue;
    }

    const formatCurrency = (value) => {
        // console.log(value)
        if (value != null) {
          return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        } else {
          return "$0.00";
        }
        
    }

const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.dateFormat);
    }

    const balanceBodyTemplate = (rowData) => {
        return formatCurrency(rowData.value);
    }


  const renderHeader = () => {
        return (
            <div className="flex justify-space-between">
                <h2 className="text-xl text-900 font-bold">Invoice History:</h2>
                {/* <span className="p-input-icon-left"> */}
                {/*     <i className="pi pi-search" /> */}
                {/*     <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" /> */}
                {/* </span> */}
            </div>
        );
    };
  const header = renderHeader();

  const renderJobsHeader = () => {
        return (
            <div className="flex justify-space-between">
                <h2 className="text-xl text-900 font-bold">Jobs:</h2>
                {/* <span className="p-input-icon-left"> */}
                {/*     <i className="pi pi-search" /> */}
                {/*     <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" /> */}
                {/* </span> */}
            </div>
        );
    };
  const jobsheader = renderJobsHeader();
  const footer = `In total there are ${liens ? liens.length : 0} payments.`;

	 useEffect(() => {
	    let isMounted = true;
	    console.log(userFull._id)
	    console.log(user.email)
	    handleUserUpdate(user.email).then((data) => {
        console.log(data)
	      if (data._id) {
	        setUser(data)
	        console.log(userFull)
          if (data._id) {
            handleContractorUpdate(data._id).then((data2) => {
              if (data2) {
                setContractor(data2)
                console.log(contractor)
                handleDataUpdate(data._id).then((data3) => {
                if (isMounted) {
                    setLiens(data3)
                    console.log(contractor)
                    handleJobsUpdate(data._id).then((data4) => {
                    if (isMounted) {
                        // setJobs(data4)
                      }
                   })
                  }
               })
                }
            })
          }
		    
		    }
		    })
	  }, []);

    return (
    	<>
    	<Card title={contractor.name}>
	    <p>
	       <strong>Company: </strong>{contractor.company}<br></br>
	       <strong>Email: </strong>{contractor.email}<br></br>
	    </p>
	</Card>
  <Card>
    <DataTable header={jobsheader} value={jobs} tableStyle={{ minWidth: '50rem' }}>
        <Column field="number" header="Job Number"></Column>
        <Column field="name" header="Name"></Column>
        <Column field="location" header="Location"></Column>
        <Column field="job_total" header="Total"></Column>
        <Column field="status" body={statusBodyTemplate} header="Recent Lien Status"></Column>
        <Column field="grand_total" header="Grand Total"></Column>
        <Column field="total_retention" header="Total Retention"></Column>
    </DataTable>
    </Card>
	  <Card>
	      <DataTable header={header} value={liens} showGridlines tableStyle={{ minWidth: '50rem' }} globalFilterFields={['invoice', 'value', 'contractor', 'status', 'jobNumber']} emptyMessage="No Payments found.">
	        {/* <Column field="date" header="Date" sortable dataType="date" body={dateBodyTemplate} /> */}
	        <Column field="dateFormat" header="Date"  filterField="dateFormat" dataType="date" style={{ minWidth: '8rem' }} body={dateBodyTemplate}
	                        // filter 
	                        // filterElement={dateFilterTemplate} 
	                        />
	        <Column field="value" header="Value" dataType="numeric" body={balanceBodyTemplate}></Column>
	        <Column field="jobNumber" header="Job Number"></Column>
	        <Column field="invoice" header="Invoice"></Column>
	        {/* <Column field="contractorCompany" header="Contractor"></Column> */}
	        <Column field="status" body={statusBodyTemplate} header="Status"></Column>
	        <Column body={modalOpenTemplate}></Column>
	      </DataTable>
	    </Card>
    	</>
   	)


};

export default withAuthenticationRequired(PdfComponent, {
  onRedirecting: () => <Loading />,
});
