import React, { Component,useRef } from "react";
import {
  useAuth0,
  withAuthenticationRequired
} from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { Container, Row, Col } from "reactstrap";
import {
  Form
} from 'react-bootstrap';

import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Toast } from 'primereact/toast';

import { faTrash, faEdit, faEye } from '@fortawesome/free-solid-svg-icons'
import styles from './styles.module.css';

const job_total = 0;
var state_check = false;

class AddJob extends Component {


  constructor(props) {
    console.log(props)
    super(props);

    // this.toast = React.createRef(null);
    this.toastBC = React.createRef(null);

    this.state = {
      "userFull": this.props.user,
      "form": {
        "project_manager_id": '',
        "project_manager_name": '',
        "number": "",
        "name": "",
        "location": "",

        "contractors": [
          {
            "id": "",
            "name": "",
            "company": "",
            "retention": "",
            "job_total": "",
            "status": "",
            "grand_total": ""
          }
        ]
      },
      "newContractor": {
        "name": "",
        "password": "",
        "company": "",
        "email": ""
      },
      "contractors_list": [],
      "newContractorState": false,
      "newJobState": false,
      "editJobState": false,
      "editJobID": null,
      "jobs": [],
      "showRows": [],
      "liens": [],
      "jobAddErrors": '',
      "jobAddErrorsStatus": false
    }
    this.toggleJobModel = this.toggleJobModel.bind(this);
    this.closeJobsModel = this.closeJobsModel.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.inputChangeContractors = this.inputChangeContractors.bind(this);
    this.addJobSingle = this.addJobSingle.bind(this);
    this.handleDataUpdate = this.handleDataUpdate.bind(this);
    // this.handleJobsUpdate = this.handleJobsUpdate.bind(this);
    this.getContractors = this.getContractors.bind(this);
    this.addContractor = this.addContractor.bind(this);
    this.deleteContractor = this.deleteContractor.bind(this);
    this.deleteJob = this.deleteJob.bind(this);
    this.saveJobSingle = this.saveJobSingle.bind(this);
    // this.viewContractors = this.viewContractors.bind(this);
    this.addContractorSave = this.addContractorSave.bind(this)
    this.closeContractorModel = this.closeContractorModel.bind(this)
    this.toggleContractorModel = this.toggleContractorModel.bind(this)
  };

  toggleContractorModel() {
    this.setState({
      newContractorState: true
    });
  }

  closeContractorModel() {
    var clearform = this.state.newContractor;
    clearform =
          {
            "name": "",
            "password": "",
            "company": "",
            "email": ""
          };

    this.setState({
      newContractorState: false,
      newContractor: clearform
    });
  }


  async addContractorSave() {
    // console.log(this.state.newContractor)
    var result = await fetch("https://api.sandershylandtest.com/contractor/add/", {
           method: "POST",
           // mode:'no-cors',
           headers: {
             "Content-Type": "application/json",
           },
           body: JSON.stringify(this.state.newContractor),
        })
        .then((result) => result.json())
        .catch(function(error) {
          console.log(error);
        });

        // console.log(result)
        if (result.success === false) {
          //// show error
        } else {
          this.closeContractorModel()
        }
  }

  inputContractorChange(name, value) {
    // console.log(name)
    // console.log(value)
    const formCurrent = this.state.newContractor;
    formCurrent[name] = value;
    this.setState({
      newContractor: formCurrent
    });
    console.log(this.state.form)
  }

  // viewContractors(index) {
  //   // console.log(index)
  //   var currentShowRows = this.state.showRows;
  //   // console.log(currentShowRows)
  //   var status = currentShowRows[index].showRow;
  //   if (status === true) {
  //     currentShowRows[index].showRow = false
  //   } else {
  //     currentShowRows[index].showRow = true
  //   }
  //   this.setState({
  //     showRows: currentShowRows
  //   });
  // }

  editJobChange(index) {
    // console.log(this.state.jobs[index])
    var clearform = this.state.form;
    clearform.project_manager_id = this.state.jobs[index].project_manager_id;
    clearform.project_manager_name = this.state.jobs[index].project_manager_name;
    clearform.number = this.state.jobs[index].number;
    clearform.name = this.state.jobs[index].name;
    clearform.location = this.state.jobs[index].location;
    clearform.contractors = this.state.jobs[index].contractors;
    this.setState({
      form: clearform,
      newJobState: true,
      editJobState: true,
      editJobID: this.state.jobs[index]._id
    });
  }

  async saveJobSingle(index) {
    // console.log(index)
    var jobs = await fetch("https://api.sandershylandtest.com/job/save/"+ index, {
           method: "POST",
           // mode:'no-cors',
           headers: {
             "Content-Type": "application/json",
           },
           body: JSON.stringify(this.state.form),
        })
        .then((jobs) => jobs.json())
        .catch(function(error) {
          console.log(error);
        });
        var form = {
            "project_manager_id": this.state.userFull._id,
            "project_manager_name": this.state.userFull.name,
            "number": "",
            "name": "",
            "location": "",
            "contractors": [
              {
                "id": "",
                "name": "",
                "retention": "",
                "job_total": "",
                "status": "",
                "grand_total": ""
              }
            ]
          };
        this.setState({
          newJobState: false,
          editJobState: false,
          editJobID: null,
          form: form,
          jobs: jobs
        });
        window.location.reload(false);
      }

  async deleteJob(index) {
    // console.log(index)
    var jobID = this.state.jobs[index]._id;
    // console.log(jobID)
    var jobs = await fetch("https://api.sandershylandtest.com/job/delete/"+ jobID, {
           method: "POST",
           // mode:'no-cors',
           headers: {
             "Content-Type": "application/json",
           },
           body: JSON.stringify(this.state.form),
        })
        .then((jobs) => jobs.json())
        .catch(function(error) {
          console.log(error);
        });
        var form = {
            "project_manager_id": this.state.userFull._id,
            "project_manager_name": this.state.userFull.name,
            "number": "",
            "name": "",
            "location": "",
            "contractors": [
              {
                "id": "",
                "name": "",
                "retention": "",
                "job_total": "",
                "status": "",
                "grand_total": ""
              }
            ]
          };
        this.setState({
          newJobState: false,
          editJobState: false,
          editJobID: null,
          form: form,
          jobs: jobs
        });
  }

  deleteContractor(e, index) {
    e.preventDefault()
    var currentForm = this.state.form
    var formContractors = currentForm.contractors;
    formContractors.splice(index, 1)
    currentForm.contractors = formContractors;
    this.setState({
      form: currentForm
    });
  }

  toggleJobModel() {
    this.setState({
      newJobState: true
    });
    // console.log(this.state.newJobState)
  }

  closeJobsModel() {
    var clearform = this.state.form;
    clearform.project_manager_id = this.state.userFull._id;
    clearform.project_manager_name = this.state.userFull.name;
    clearform.number = "";
    clearform.name = "";
    clearform.location = "";
    clearform.contractors = [
          {
            "id": "",
            "name": "",
            "retention": "",
            "job_total": "",
                "status": "",
                "grand_total": ""
          }
        ];

    this.setState({
      newJobState: false,
      form: clearform
    });
  }

  inputChange(name, value) {
    // console.log(name)
    // console.log(value)
    const formCurrent = this.state.form;
    formCurrent[name] = value;
    this.setState({
      form: formCurrent
    });
    console.log(this.state.form)
  }

  inputChangeContractors(index, type, value) {
    // console.log(index)
    // console.log(type)
    // console.log(value)
    var currentForm = this.state.form
    var formContractors = currentForm.contractors;

    if (type === 'id') {
      var currentContractor = this.state.contractors_list.find(x => x._id === value);
      formContractors[index].id = value;
      formContractors[index].name = currentContractor.name;
    } else {
      formContractors[index].retention = value;
      formContractors[index].job_total = "";
    }
    this.setState({
      form: currentForm
    });
  }

  async addJobSingle() {
    // console.log(this.state.form)
    if (this.state.form.number != "" && this.state.form.name != "" && this.state.form.location != "" && this.state.form.contractors.length != 0) {
       var response = await fetch("https://api.sandershylandtest.com/job/add", {
           method: "POST",
           // mode:'no-cors',
           headers: {
             "Content-Type": "application/json",
           },
           body: JSON.stringify(this.state.form),
        })
        .then((response) => response.json())
        .catch(function(error) {
          console.log(error);
        });
        console.log(response)
        if (response.message === false) {
          console.log('This Job already exists!!!')
          this.toastBC.current.show({ severity: 'error', summary: "ERROR", detail: 'This Job already exists!!!' });
        } else {
          this.setState({
            jobs: response.jobs
          })
          window.location.reload(false);
        }
        // window.location.reload(false);
    } else {
      var errorsText = 'Please add these fields before saving: ';
      if (this.state.form.number === "") {
        errorsText += "Number, "
      }
      if (this.state.form.number === "") {
        errorsText += "Number, "
      }
      if (this.state.form.number === "") {
        errorsText += "Location, "
      }
      if (this.state.form.number === "") {
        errorsText += "Contractors"
      }
      this.setState({
        jobAddErrors: errorsText,
        jobAddErrorsStatus: true
      })
    }
   
      }


  async handleDataUpdate(email) {
    // console.log(email)
    const user = await fetch("https://api.sandershylandtest.com/user/"+ email, {
       method: "GET",
       // mode:'no-cors',
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((response) => response.json())

    // console.log(this.props.user,)
    var jobs = await fetch("https://api.sandershylandtest.com/jobs-pm/"+ user._id, {
       method: "GET",
       // mode:'no-cors',
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((response) => response.json())
    var currentLiens = await fetch("https://api.sandershylandtest.com/liens/project_manager/"+ user._id, {
       method: "GET",
       // mode:'no-cors',
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((response) => response.json())

     
    // console.log(currentLiens)
      var reset = this;
      // console.log(user)
      var currentForm = this.state.form;
      var contractors_list = this.state.contractors_list;
      // console.log(contractors_list)
      currentForm.project_manager_id = user._id;
      currentForm.project_manager_name = user.name;
      // console.log(jobs)
      // setJobs(jobs)
      var currentShowRows = [];
      jobs.forEach(function(job, i) {
        currentShowRows.push({"showRow": true})
        const job_index = i;
        job.total = 0;
        job.trtd = 0;
        job.contractors.map((contractor, x) => {
          // console.log(contractor)
          const contractor_index = x;
          if (currentLiens.length > 0){
          const response_liens = currentLiens.filter(lien => {
            // console.log(lien)
            // console.log(contractor)
            // console.log(job)
            return lien.contractor_id === contractor.id && lien.jobNumber === job.number;
          });

          console.log(response_liens)
          const data = response_liens[0];
          // console.log(jobs[job_index].contractors[contractor_index])
          const contractor_info = contractors_list.filter(contractor_item => {
            // console.log(contractor_item)
            return contractor_item._id === contractor.id;
          });
          // console.log(contractor_info)
          if (response_liens.length > 0){
            var job_contractor_total = 0;
            if (contractor_info.length > 0) {
              jobs[job_index].contractors[contractor_index].company = contractor_info[0].company;
            }
            response_liens.forEach(function(lien, z) {
              if (lien.status === "approved" || lien.status === "completed") {
                 console.log(lien)
                  if (lien.lineItems_manHours_total) {} else {
                    lien.lineItems_manHours_total = 0
                  }
                  if (lien.lineItemsTotal) {} else {
                    lien.lineItemsTotal = 0
                  }
                  if (lien.lineItems_other_total) {} else {
                    lien.lineItems_other_total = 0
                  }
                  var job_total = ((Number(lien.lineItems_manHours_total) + Number(lien.lineItemsTotal)+ Number(lien.lineItems_other_total)) - ((Number(lien.lineItems_manHours_total) + Number(lien.lineItemsTotal) + Number(lien.lineItems_other_total)) * (jobs[job_index].contractors[contractor_index].retention/100)));
                  console.log(job_total)
                  job_contractor_total += job_total;
                  // console.log(job_contractor_total)
              }
             
            })

            jobs[job_index].contractors[contractor_index].job_total = "$" + ((Number(data.lineItems_manHours_total) + Number(data.lineItemsTotal)+ Number(data.lineItems_other_total)) - ((Number(data.lineItems_manHours_total) + Number(data.lineItemsTotal)+ Number(data.lineItems_other_total)) * (jobs[job_index].contractors[contractor_index].retention/100))).toFixed(2).toString()
            jobs[job_index].contractors[contractor_index].status = data.status.toUpperCase();
            jobs[job_index].contractors[contractor_index].grand_total = job_contractor_total.toFixed(2).toString();
            jobs[job_index].contractors[contractor_index].total_retention = (job_contractor_total * (data.retention/100)).toFixed(2).toString();
            job.total += job_contractor_total;
            job.trtd += (job_contractor_total * (data.retention/100));
            if (x === job.contractors.length -1) {
              if (i === jobs.length -1) {
                reset.setState({
                  jobs: jobs,
                  showRows: currentShowRows,
                  userFull: user,
                  form: currentForm
                });
              }
            }
          } else {
            var job_contractor_total = 0;
            if (contractor_info.length > 0) {
              jobs[job_index].contractors[contractor_index].company = contractor_info[0].company;
            }
            
            jobs[job_index].contractors[contractor_index].job_total = "No Total Yet";
            jobs[job_index].contractors[contractor_index].status = "UNSUBMITTED";
            jobs[job_index].contractors[contractor_index].grand_total = 0.00;
            jobs[job_index].contractors[contractor_index].total_retention = 0.00;
            job.total += 0;
            job.trtd += 0;
            if (x === job.contractors.length -1) {
              if (i === jobs.length -1) {
                reset.setState({
                  jobs: jobs,
                  showRows: currentShowRows,
                  userFull: user,
                  form: currentForm
                });
              }
            }
          }
        }
        })

      })
      // console.log(currentShowRows)
      
  }

//   async handleJobsUpdate(id) {
//     // console.log(id)
// 
//       return
//   }

  async getContractors(id) {
    // console.log(id)
      var contractors = await fetch("https://api.sandershylandtest.com/all/contractors", {
       method: "GET",
       // mode:'no-cors',
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((contractors) => contractors.json())
      // console.log(contractors)
      // setContractors(jobs)
      var alphaContractors = contractors.sort((a, b) => a.email.localeCompare(b.email))
      this.setState({
        contractors_list: alphaContractors
      });
      return alphaContractors
  }



  addContractor() {
    const formCurrent = this.state.form;
    const currentContractors = formCurrent.contractors;
    const newContractor = {
      "id": "",
      "name": "",
      "company": "",
      "retention": "",
      "job_total": "",
      "status": "",
      "grand_total": ""
    };
    currentContractors.push(newContractor)
    formCurrent.contractors = currentContractors;
    this.setState({
      form: formCurrent
    });
  }


  componentDidMount() {
    this.getContractors().then((data) => {
      this.handleDataUpdate(this.props.user.email).then((data) => {
        // this.handleJobsUpdate(data._id).then((data) => {
            // this.getLiens().then((data) => {
            // })
          })
        // })
      })

  }

  componentDidUpdate(prevProps, prevState, snapshot, nextProps) {
    console.log(prevProps.newPMState)
    console.log(this.props.newPMState)
    if(prevProps.newSettings !== this.props.newSettings) {
      if ( this.props.newSettings )
        this.setState({
          editJobState: this.props.newSettings.state,
          newJobState: this.props.newSettings.state,
          form: this.props.newSettings.job,
          editJobID: this.props.newSettings.id
        });
    }
  }

  render () {
    return(
    <div className="fullPage">
    <div className = "pageButtons" >
    <Button label="New Job" raised rounded onClick = {
      () => this.toggleJobModel()
    }/>
    <Button label="New Contractor" raised rounded onClick = {
      () => this.toggleContractorModel()
    }/> 
    </div>

    <div className = {this.state.newContractorState ? 'newContractorFormHolder active' : 'newContractorFormHolder'}>
      <div>
      <div className="newContractorForm">
      <Form>
      <div className="modelTitle">Add a New Contractor</div>
      <Form.Group className = "form_row full" >
      <Form.Label >
      <span > Company: < /span>
      <Form.Control type = "text"
      name = "company"
      value = {this.state.newContractor.company}
      onChange = {
        (e) => {
          this.inputContractorChange("company", e.target.value)
        }
      }/>
      </Form.Label>
      </Form.Group>
      <Form.Group className = "form_row full" >
      <Form.Label >
      <span > Name: < /span>
      <Form.Control type = "text"
      name = "name"
      value = {this.state.newContractor.name}
      onChange = {
        (e) => {
          this.inputContractorChange("name", e.target.value)
        }
      }/>
      </Form.Label>
      </Form.Group>
      <Form.Group className = "form_row full" >
      <Form.Label >
      <span > Email: < /span>
      <Form.Control type = "email"
      name = "email"
      value = {this.state.newContractor.email}
      onChange = {
        (e) => {
          this.inputContractorChange("email", e.target.value)
        }
      }/>
      </Form.Label>
      </Form.Group>
      <Form.Group className = "form_row full" >
      <Form.Label >
      <span > Password: < /span>
      <Form.Control type = "password"
      name = "password"
      value = {this.state.newContractor.password}
      onChange = {
        (e) => {
          this.inputContractorChange("password", e.target.value)
        }
      }/>
      </Form.Label>
      </Form.Group>

      
      </Form>

      <div className = "formButtons" >
        <Button className="submitButton active" severity = "primary" type = "button" onClick = {() => this.addContractorSave()} >SAVE </Button>
        <Button severity = "danger" onClick={(e) => this.closeContractorModel()} > CANCEL </Button>
      </div>
      </div>
      </div>
    </div>

    <div className = {this.state.newJobState ? 'newJobModel active' : 'newJobModel'} >
    <div>
    <Form>
            <div className={this.state.jobAddErrorsStatus ? 'error active' : 'error'}>{this.state.jobAddErrors}</div>
    <div className="modelTitle">Add a New Job</div>
    <Form.Group className = "form_row full" >
    <Form.Label >
    <span > Number: < /span>
    <Form.Control type = "text"
    name = "number"
    value = {this.state.form.number}
    onChange = {
      (e) => {
        this.inputChange("number", e.target.value)
      }
    }/>
    </Form.Label>
    </Form.Group>
    <Form.Group className = "form_row full" >
    <Form.Label >
    <span> Name: < /span>
    <Form.Control type = "text"
    name = "name"
    value = {this.state.form.name}
    onChange = {
      (e) => {
        this.inputChange("name", e.target.value)
      }
    }
    />
    </Form.Label>
    </Form.Group>
    <Form.Group className = "form_row full" >
    <Form.Label >
    <span > Location: < /span>
    <Form.Control type = "text"
    name = "location"
    value = {this.state.form.location}
    onChange = {
      (e) => {
        this.inputChange("location", e.target.value)
      }
    }
    />
    </Form.Label>
    </Form.Group>
    <Form.Group className = "form_row full" >
      <Form.Label >
      <span > Budget: < /span>
      <Form.Control type = "number"
      name = "budget"
      value = {this.state.form.company}
      onChange = {
        (e) => {
          this.inputChange("budget", e.target.value)
        }
      }/>
      </Form.Label>
      </Form.Group>
    {this.state.form.contractors.map((contractor, i) => {
      if (i === 0) {
        return (
           <Form.Group key={i} className="form_row contractorRow">
           <Form.Group className="form_row half">
           <Form.Label>
           <span> Contractor: </span>
           <Form.Select name="contractor" value={contractor.id} onChange={(e) => {this.inputChangeContractors(i, "id", e.target.value)}}>
                <option key="none" value="">Select One</option>
                {this.state.contractors_list.map((item, x) => {
                   return (
                     <option key={x} value={item._id}>{item.company}</option>
                   );
                 })}
          </Form.Select>
           </Form.Label>
           </Form.Group>
           <Form.Group className="form_row retention">
           <Form.Label>
           <span> Retention: </span>
           <Form.Control type="text" name="retention" value={contractor.retention} onChange = {(e) => {this.inputChangeContractors(i, "retention", e.target.value)}}/>
           </Form.Label>
           </Form.Group>
           <Form.Group className="form_row contractorDelete">
           <Form.Label>
           <Button className="" variant="danger"><FontAwesomeIcon icon={faTrash}  onClick = {(e) => {this.deleteContractor(e,i)}} /></Button>
           </Form.Label>
           </Form.Group>
           </Form.Group>
         )
      } else {
        return (
           <Form.Group key={i} className="form_row contractorRow">
           <Form.Group className="form_row half">
           <Form.Label>
           <Form.Select name="contractor" value={contractor.id} onChange={(e) => {this.inputChangeContractors(i, "id", e.target.value)}}>
                <option key="none" value="">Select One</option>
                {this.state.contractors_list.map((item, x) => {
                   return (
                     <option key={x} value={item._id}>{item.company}</option>
                   );
                 })}
           </Form.Select>     </Form.Label>
           </Form.Group>
           <Form.Group className="form_row retention">
           <Form.Label>
           <Form.Control type="text" name="retention" value={contractor.retention} onChange = {(e) => {this.inputChangeContractors(i, "retention", e.target.value)}}/>
           </Form.Label>
           </Form.Group>
           <Form.Group className="form_row contractorDelete">
           <Form.Label>
           <Button className="" variant="danger"><FontAwesomeIcon icon={faTrash}  onClick = {(e) => {this.deleteContractor(e, i)}} /></Button>
           </Form.Label>
           </Form.Group>
           </Form.Group>
         )
      }
    })}

    
    </Form>
    <div className = "formButtons" >

    <Button className={this.state.editJobState ? 'submitButton' : 'submitButton active'} label="ADD JOB" raised rounded severity="primary" onClick = {() => this.addJobSingle()} />
    <Button className={this.state.editJobState ? 'submitButton active' : 'submitButton'} label="SAVE JOB" raised rounded severity="primary" onClick = {() => this.saveJobSingle(this.state.editJobID)} />
    <Button raised rounded severity="success" label="ADD CONTRACTOR" onClick = {() => this.addContractor()} />
    <Button raised rounded severity="danger" label="CANCEL" onClick = {() => this.closeJobsModel()} /> 
    </div>
    </div>
    </div>

    <div className="Jobs">

    </div>

          <Toast ref={this.toast} />
          <Toast ref={this.toastBC} position="center" />
    </div>
  )
  }
}

export default AddJob;
