import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import SignaturePad from 'react-signature-canvas'
import { Alert } from "reactstrap";
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import moment from 'moment-timezone';
import {Form} from 'react-bootstrap';
import styles from './styles.module.css'

class Signature extends Component {

  constructor(props) {
    console.log(props)
    super(props);
    this.toast = React.createRef(null);
    this.toastBC = React.createRef(null);
    var date = moment().format()
    const user = this.props.user;
    this.state = {
      "trimmedDataURL": null,
      "signActive": false,
      "commentsActive": false,
      "comments": "",
      "jobs": this.props.jobs,
      "lien": this.props.data
    }
    this.commentApp = this.commentApp.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.commentAdd = this.commentAdd.bind(this);
    this.commentCancel = this.commentCancel.bind(this);
    this.signApp = this.signApp.bind(this);
    this.trim = this.trim.bind(this);
    this.updateSigImage = this.updateSigImage.bind(this);
    this.close = this.close.bind(this);
    this.closeComment = this.closeComment.bind(this);
    this.confirm = this.confirm.bind(this);
    this.show = this.show.bind(this);
    this.clear = this.clear.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this)
  };

      show() {
        this.toast.current.show({ severity: 'success', summary: 'Submission Received', detail: 'Thank you, we have received your submission.' });
    };

    clear_toast(submit) {
        this.toastBC.current.clear();
    };

    async handleInputChange(e) {
      console.log(e.target.value)
      var newJob = this.state.jobs.find(x => x._id === e.target.value);
      console.log(newJob)
      var lienNow = this.state.lien;
      lienNow.jobNumber = newJob.number;
      lienNow.job_id = newJob._id;
      this.setState({
        "lien": lienNow,
      });
    }

    async handleSubmit() {
      this.clear_toast(false)
      console.log(this.sigPad.getTrimmedCanvas().toDataURL('image/png'))
      var signature_image = this.sigPad.getTrimmedCanvas().toDataURL('image/png').toString();
      this.setState({
        trimmedDataURL: signature_image,
        signActive: false
      });
      var newData = this.state.lien;
      newData.pm_signature = signature_image;
      newData.status = 'approved';
      console.log(newData)
      var response = await fetch("https://api.sandershylandtest.com/update-signature/" + this.props.data._id, {
           method: "POST",
           // mode:'no-cors',
           headers: {
             "Content-Type": "application/json",
           },
           body: JSON.stringify(newData),
        })
        .then((response) => response.json())
        console.log(response)
          this.props.history.push('/liens/')
    }

    confirm(e) {
      this.setState({
        signActive: false
      });
      // this.clear_toast(true)
      e.preventDefault();
      var m = moment().day();
      console.log(m)
      if (m === 3) {
        var a = moment().hour();
        console.log(a)
        if (a > 10) {
          this.toastBC.current.show({
            severity: 'warn',
            sticky: true,
            className: 'border-none',
            content: (
                <div className="flex flex-column align-items-center" style={{ flex: '1' }}>
                    <div className="text-center">
                        <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                        <div className="font-bold text-xl my-3">Please be aware that this was submitted after the 10am Central cut off and will be approved for next weeks pay period.<br></br><br></br>Click accept if you understand.<br></br></div>
                    </div>
                    <div className="flex gap-2">
                        <Button onClick={(e) => this.handleSubmit(true)} type="button" label="Confirm" className="p-button-success w-6rem" />
                        <Button onClick={(e) => this.clear_toast(false)} type="button" label="Cancel" className="p-button-warning w-6rem" />
                    </div>
                </div>
            )
        });
        } else {
          // this.toast.current.show({ severity: 'success', summary: 'Submission Received', detail: 'Its Wednesday before 10' });
          // console.log('')
          this.handleSubmit()
        }
      } else {
        this.handleSubmit()
      }

        
    };

  sigPad = {}
  clear = () => {
    this.sigPad.clear()
  }

  close() {
    this.sigPad.clear()
    this.setState({signActive:false});
  }

  closeComment() {
    this.setState({commentsActive:false});
  }

  async updateSigImage() {
    console.log(this.state)
  }
  async trim() {
    
    this.confirm()
  }
  async signApp() {
      var lien = await fetch("https://api.sandershylandtest.com/lien/project_manager/"+ this.props.lienID, {
       method: "GET",
       // mode:'no-cors',
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((lien) => lien.json())
      console.log(lien[0])
      if (!lien[0].lineItems_other) {
        lien[0].lineItems_other = [];
        lien[0].lineItems_other_total = 0;
      }

    var jobs = await fetch("https://api.sandershylandtest.com/jobs-pm/"+ lien[0].projectManagerId, {
       method: "GET",
       // mode:'no-cors',
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((response) => response.json())
      this.setState({
        "jobs": jobs,
        "lien": lien[0],
        signActive:true
      });
      return lien[0];
    
  }

  async inputChange(value) {
    console.log(value)
    this.setState({"comments":value});
  }

  async commentApp() {
     var lien = await fetch("https://api.sandershylandtest.com/lien/project_manager/"+ this.props.lienID, {
       method: "GET",
       // mode:'no-cors',
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((lien) => lien.json())
      console.log(lien[0])
      if (!lien[0].lineItems_other) {
        lien[0].lineItems_other = [];
        lien[0].lineItems_other_total = 0;
      }

    var jobs = await fetch("https://api.sandershylandtest.com/jobs-pm/"+ lien[0].projectManagerId, {
       method: "GET",
       // mode:'no-cors',
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((response) => response.json())
      this.setState({
        "jobs": jobs,
        "lien": lien[0],
        commentsActive:true
      });
      return lien[0];
  }

  async commentAdd() {
    var newData = this.state.lien;
    newData.comments = this.state.comments;
    newData.status = 'rejected';
    console.log(newData)
    var response = await fetch("https://api.sandershylandtest.com/lien/rejected/" + this.props.data._id, {
       method: "POST",
       // mode:'no-cors',
       headers: {
         "Content-Type": "application/json",
       },
       body: JSON.stringify(newData),
    })
    .then((response) => response.json())
    console.log(response)

    this.setState({commentsActive:false});
    this.props.history.push('/liens/')

  }

  commentCancel() {
    this.setState({commentsActive:false});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log(prevState)
    console.log(snapshot)
    console.log(this.state)
  }
  render () {
    return <div>
    <div className={ this.state.signActive ? 'signature active' : 'signature' }>
      <div className="signatureInner">
         <div className="instructions">Change Job Number:</div>
        <div className="job_number_change">
          <Form.Select name="jobNumber" value={this.state.lien.job_id} onChange={this.handleInputChange}  aria-label="Default select example">
            <option id="none" key="none">Select One</option>
            {this.state.jobs.map((job, i) => {
              // if (job.project_manager_name != "Trey Jackson") {
              console.log(job.contractors)
              if (job.contractors) {
                // console.log(this.state.contractor._id)
                var jobCheck = job.contractors.find(x => x.id === this.state.lien.contractor_id);
                // console.log(jobCheck)
                if (jobCheck != undefined) {
                   return (
                 <option key={i} value={job._id}>{job.number} - {job.name}</option>
               );
                }
              
              }
            // }
              
             })}
            </Form.Select>
        </div>
        <div className="instructions">Sign Here:</div>
        <div className={styles.container}>
          <div className={styles.sigContainer}>
          <SignaturePad canvasProps={{height: 200, width: 400, className: styles.sigPad}}
            ref={(ref) => { this.sigPad = ref }} />
          </div>
          <div>
            <button className={styles.buttons} onClick={this.clear}>
              CLEAR
            </button>
            <button className={styles.buttons} onClick={this.close}>
              CLOSE
            </button>
            <button className={styles.buttons} onClick={this.confirm}>
              SAVE
            </button>
          </div>
        </div>
      </div>
    </div>

    <div className={ this.state.commentsActive ? 'comments active' : 'comments' }>
      <div className="signatureInner">
        <div className="instructions">Comment Here:</div>
        <div className={styles.container}>
          <div className={styles.sigContainer}>
            <textarea name="comments" onChange = {
              (e) => {
                this.inputChange(e.target.value)
              }
            }></textarea>
          </div>
          <div>
            <button className={styles.buttons} onClick={this.commentCancel}>
              CANCEL
            </button>
            <button className={styles.buttons} onClick={this.closeComment}>
              CLOSE
            </button>
            <button className={styles.buttons} onClick={this.commentAdd}>
              SUBMIT
            </button>
          </div>
        </div>
      </div>
    </div>

    <div className='status started'>
      <Button severity="warning" rounded raised onClick={this.commentApp}>COMMENT</Button>
      <Button severity="success" rounded raised onClick={this.signApp}>APPROVE AND SIGN</Button>
    </div>


          <Toast ref={this.toast} />
          <Toast ref={this.toastBC} position="center" />
    </div>
  }
}

export default Signature;
